<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <el-form :model="form" :rules="rules" ref="form" :inline="true" label-position="right">
          <el-form-item label="选择标段" prop="bidId" style="width: 100%">
            <el-cascader
              v-model="form.bidId"
              placeholder="请选择"
              :options="bidList"
              :show-all-levels="false"
              @change="handleBidChange"
              filterable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="选择网格" prop="gridId" style="width: 100%">
            <el-select
              v-model="form.gridId"
              placeholder="请选择"
              @change="handleGridChange"
              filterable
            >
              <el-option
                v-for="(item, idx) in gridList"
                :key="idx"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <div class="copy-item">
            <div class="p24px">
              <el-form-item label="选择已经排班班次" prop="fromShiftId" style="width: 100%">
                <el-select
                  placeholder="请选择"
                  v-model="form.fromShiftId"
                  @change="handlefromShiftChange"
                  filterable
                >
                  <el-option
                    v-for="(item, idx) in fromShiftIdList"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                label="选择已经排班日期"
                prop="scheduleDay"
                style="width: 100%"
              >
                <el-date-picker
                  v-model="form.scheduleDay"
                  type="date"
                  placeholder="选择日期"
                  format="yyyy-MM-dd"
                  value-format="yyyyMMdd"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </div>
          </div>
          <div class="copy-item">
            <div class="p24px">
              <el-form-item label="选择需要排班班次" prop="needScheduleShiftIds" style="width: 100%">
                <el-select
                  v-model="form.needScheduleShiftIds"
                  placeholder="请选择"
                  @change="handleNeedScheduleShiftChange"
                  filterable
                >
                  <el-option
                    v-for="(item, idx) in toShiftIdList"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="选择需要排班日期" prop="needScheduleDayList" style="width: 100%">
                <el-date-picker
                  v-model="form.needScheduleDayList"
                  type="dates"
                  placeholder="选择一个或多个日期"
                  format="yyyy-MM-dd"
                  value-format="yyyyMMdd"
                  :picker-options="pickerOptions2"
                  @change="handleChange"
                ></el-date-picker>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <div class="tac" style="margin-top: 40px">
        <el-button
          type="success"
          class="btn-success-hw"
          :loading="submitLoading"
          @click="onSubmit"
        >确认</el-button>
        <el-button type="danger" style="margin-left: 30px" @click="onClose">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: [{ name: "复制人员排班", path: "" }],
      submitLoading: false,      
      //↓ for <select></select>
      bidList: [],
      gridList: [], //网格list
      fromShiftIdList: [], //已经排班班次list
      toShiftIdList:[],//需要排班的班次list
      fromScheduleDayMap: null, //已经排班日期list，一个hashamp
      ///////////////////////////////
      form: {
        //↓ for v-model
        bidId: null,
        gridId: null,
        fromShiftId: null,
        needScheduleShiftIds: null,
        //↓ 提交表单专用字段，,和API一一对应
        shiftIdList: [], //已排班的班次编号list        
        scheduleDay: "", //已排班的日期
        needScheduleShiftIdList: [], //需要排班班次list
        needScheduleDayList: [] //需要排班的日期list
      },
      rules: {
        bidId: [{ required: true, message: "请选择标段", trigger: ["blur","change"] }],
        gridId: [{ required: true, message: "请选择网格", trigger: "change" }],
        fromShiftId: [
          { required: true, message: "请选择已经排班班次", trigger: "change" }
        ],
        scheduleDay: [
          { required: true, message: "请选择需要排班班次", trigger: "change" }
        ],
        needScheduleShiftIds: [
          { required: true, message: "请选择已经排班日期", trigger: "change" }
        ],
        needScheduleDayList: [
          { required: true, message: "选择需要排班日期", trigger: "change" }
        ]
      },
      pickerOptions: {
        disabledDate(time) {return true}
      },
      pickerOptions2:{
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      }
    };
  },
  methods: {
    handleBidChange(value) {
      // debugger;
      let self = this;
      if (value.length)
        self.$api_hw
          .scheduling_getBidShiftScheduleInfo({
            bidId: value[0], //注意是value[0]，和其他地方级联菜单取value[1]不一样
            gridId: null,
            shiftIdList: null,
            type: "1"
          })
          .then(v => {
            self.gridList = v;
          })
          .catch(e => {
            console.error(e);
          });
      else {
        self.form.gridId = null;
        self.form.fromShiftId = null;
        self.form.scheduleDay = null;
        self.form.needScheduleShiftIds = null;   
        self.form.needScheduleDayList = [];
        self.gridList = [];
        self.fromShiftIdList = [];
        self.toShiftIdList=[];
        self.fromScheduleDayMap = null;
      }
    },
    handleGridChange(value) {
      //   debugger;
      let self = this;
      if (value.length)
        self.$api_hw
          .scheduling_getBidShiftScheduleInfo({
            bidId: null,
            gridId: value,
            shiftIdList: null,
            type: "2"
          })
          .then(v => {
            self.fromShiftIdList = v;
          })
          .catch(e => {
            console.error(e);
          });
      else {
        self.form.fromShiftId = null;
        self.form.scheduleDay = null;
        self.form.needScheduleShiftIds = null;
        self.form.needScheduleDayList = [];
        self.fromShiftIdList = [];
        self.toShiftIdList=[];
        self.fromScheduleDayMap = null;
      }
    },
    handlefromShiftChange(value) {
      //   debugger;
      let self = this;
      if (value.length) {
        //同步 需要排班班次下拉框
        self.needScheduleShiftIds = value;
        let _idList = [];
        if (value.indexOf(",") != -1) {
          //选了全部
          _idList = _idList.concat(value.split(","));
          self.form.needScheduleShiftIds=null;
          self.toShiftIdList = self.$_.cloneDeep(self.fromShiftIdList).splice(0,1);
        } else{ 
          self.form.needScheduleShiftIds=null;
          self.toShiftIdList = self.$_.cloneDeep(self.fromShiftIdList).splice(1);
          _idList.push(value);
          }
        self.$api_hw
          .scheduling_getBidShiftScheduleInfo({
            bidId: null,
            gridId: self.form.gridId,
            shiftIdList: _idList,
            type: "3"
          })
          .then(v => {
            if (v && v.length) {
              self.fromScheduleDayMap = new Map();
              for (let i = 0; i < v.length; i++) {
                let item = v[i];
                self.fromScheduleDayMap.set(item.label, item.value);
              }
            }
          })
          .catch(e => {
            console.error(e);
          });
      } else {
        self.form.scheduleDay = null;
        self.form.needScheduleShiftIds = null;
        self.toShiftIdList=[];
        self.form.needScheduleDayList = [];
        self.fromScheduleDayMap = null;
      }
    },
    handleNeedScheduleShiftChange(value) {
      let self = this;
      if (!value.length){
        self.form.needScheduleDayList = [];
      }
    },
    handleChange(value) {
      console.log(value);
    },
    async submit() {
      let self = this;
      await self.$api_hw.scheduling_copySchedule(_fixData(self.form));
      function _fixData(d) {
        let _d = self.$_.cloneDeep(d);
        for (let key in d) {
          switch (key) {
            case "bidId":
              delete  _d[key];
              break;
            case "gridId":
              delete  _d[key];
              break;
            case "fromShiftId":
              _d["shiftIdList"] = d[key].split(",")
              delete  _d[key];
              break;
            case "needScheduleShiftIds":
              _d["needScheduleShiftIdList"] = d[key].split(",")
              delete  _d[key];
              break;
            // case "ecoCoordinateScope":
            //   d[key] = self.mapData.lnglat.join();
            //   break;
          }
        }
        console.log(_d);
        return _d;
      }
    },
    async onSubmit() {
      var self = this;

      if (self.submitLoading) return; //防止重复提交

      self.$refs.form.validate(async valid => {
        if (!valid) return;
        self.submitLoading = true;
        try {
          await self.submit();
          self.$notify({
            title: "成功",
            message: `${self.nav[0].name}成功`,
            type: "success"
          });

          BUS.$emit(BUSEVENT.REFRESH_SCHEDULING_LIST,-1);
          self.onClose("/district-management/scheduling/scheduling-management");
          // self.$router.push("/district-management/scheduling/scheduling-management");
        } catch (e) {
          console.error(e);
        } finally {
          self.submitLoading = false;
        }
      });
    },
    onClose(toPath) {
      if (toPath && typeof toPath == "string")
          // for 标签页
          BUS.$emit(BUSEVENT.CLOSE_NAV, [this.$route, toPath]);
      else {
          BUS.$emit(BUSEVENT.CLOSE_NAV, this.$route);
      }
    }
  },
  watch: {
    fromScheduleDayMap: {
      deep: true,
      handler(v, ov) {
        // console.log("---------fromScheduleDayMap change---------", v,ov);
        let self = this;
        self.pickerOptions.disabledDate = null;
        self.pickerOptions.disabledDate = function(time) {
          function transDatgString(y, m, d) {
            if (m < 10) m = "0" + m;
            if (d < 10) d = "0" + d;
            return "" + y + m + d;
          }

          let _key = transDatgString(
            time.getFullYear(),
            time.getMonth() + 1,
            time.getDate()
          );

          // console.log(_key, self.fromScheduleDayMap);

          let _result = true;

          if (self.fromScheduleDayMap && self.fromScheduleDayMap.has(_key)) {
            let _tmp = _key.replace(
              /([0-9]{4})([0-9]{2})([0-9]{2})/,
              "$1-$2-$3 00:00:00"
            );
            let _a = new Date(_tmp).getTime(); //这天0点的时间，单位毫秒
            let _b = _a + 24 * 3600 * 1000; //这天12点00的时间，单位毫秒
            _result = time.getTime() < _a || time.getTime() >= _b;
          }

          return _result;
        };
      }
    }
  },
  async mounted() {},
  async activated() {
    this.bidList = await this.$api_hw.scheduling_getBidShiftScheduleInfo({
      bidId: null,
      gridId: null,
      shiftIdList: null,
      type: "0"
    });
  }
};
</script>

<style lang='sass' scoped>
.clearBox
  .content-box
    .content-main
      .copy-item
        width: 480px
        height: 230px
        border: 1px solid rgba(225,225,225,1)
        border-radius: 2px
        display: inline-block
        vertical-align: middle
        margin-right: 30px

</style>
